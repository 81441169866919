import React, { useRef } from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import Title from "../components/Title";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const redirect = useNavigate();

  const appartments = [
    { label: `${t("contactRoom1")}`, value: "Studio" },
    { label: `${t("contactRoom2")}`, value: "Terrassen-Studio" },
    { label: `${t("contactRoom3")}`, value: "Terrassen-Apartment" },
    { label: `${t("contactRoom4")}`, value: "Balkon-Apartment" },
    { label: `${t("contactRoom5")}`, value: "großes Apartment" },
    { label: `${t("contactRoom6")}`, value: "kleines Penthouse" },
    { label: `${t("contactRoom7")}`, value: "Penthouse" },
    
  ];
  const [selectedOption, setSelectedOption] = useState({ label: `${t("contactDefault")}`, value: null });

  const [check, setCheck] = useState(false);

  const notifySuccess = () => toast.success(`${t("contactAlert")}`);
  const notifyError = () => toast.error(`${t("contactError")}`);

  const onClear = () => {
    setSelectedOption({ label: `${t("contactDefault")}`, value: "null" });
  };


  const handleSubmit = (e) => {
    //**** Form Validation ****
    //**** Honey Pot für Bots im Input mit der id "origin" eingebaut ****
    //**** Nur wenn das Input-Feld leer ist, wird die Mail gesendet, ansonsten geht es zurück auf die Startseite **** 
    const originSubmit = document.getElementById('origin').value;
    console.log("referenceSubmit: ", originSubmit);
    if ( originSubmit !== "" ) {
      onClear();
      setCheck(false);
      redirect("/");      
    } else {    
    e.preventDefault();
    setSendingInProgress(true);
    
    emailjs
      .sendForm(
        "service_lbjo95o",
        "template_4bt7vo9",
        form.current,
        "Galjhq1_1wNlh-HkL"
      )
      .then(
        (result) => {
          form.current.reset();
          notifySuccess();
          setSendingInProgress(false);     
          onClear();
          setCheck(false);
        },
        (error) => {
          notifyError();
          setSendingInProgress(false);
          setCheck(false);
        }
      );
    }
  };

  return (
    <section className="section" id="contact">
      <Title title={t("contactTitle")} subTitle={t("contactSubTitle")} />

      <div className="contact-container">
        <form id="contact" ref={form} onSubmit={handleSubmit}>
          <h3>{t("contactHeader")}</h3>
          <br />

          <div className="form-container" style={{ display: "flex" , alignItems: "center", gap: "20px"}}>
            <input
              placeholder={t("contactLastName")}
              className="text-input"
              type="text"
              id="lastName"
              name="lastName"
              required
            />
            <input
              placeholder={t("contactFirstName")}
              className="text-input"
              type="text"
              id="firstName"
              name="firstName"
              required
            />
          </div>
          <div className="form-container" style={{ display: "none" }}>
            <input 
              placeholder="Where are you from?"
              className="text-input"
              type="text"
              id="origin"
              name="origin"
            />
          </div>
          <div className="form-container">
            <input
              placeholder={t("contactEmail")}
              className="text-input"
              type="email"
              id="email"
              name="user_email"
              required
            />
          </div>
          <div className="form-container">
            <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: 'large',
                color: '#ccc',
                background: '#fff',
                borderRadius: '15px',
                padding: '5px 10px',
                borderColor: state.isFocused ? '#ccc' : '#ccc',
                boxShadow: state.isFocused ? null : null,
                '&:hover': {
                  borderColor: state.isFocused ? '#aaa' : '#aaa',
                },
              }),
            }}
              value={selectedOption}
              onChange={setSelectedOption}
              options={appartments}
              placeholder={t("contactDefault")}
              name="habit"
              required
            />
          </div>
          <div className="form-container" style={{ display: "flex" , alignItems: "center", justifyContent: "center", gap: "20px"}}>
            <div>

              <label htmlFor="startDate" style={{position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)", marginTop: "20px"}}>{t("contactStartDate")}</label>
              <input
                placeholder={t("contactStartDate")}
                className="text-input"
                type="date"
                id="startDate"
                name="startDate"
                required
                />
                </div>
            
           
              <div>
              <label htmlFor="endDate" style={{position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)", marginTop: "20px"}}>{t("contactEndDate")}</label>
              <input              
                placeholder={t("contactEndDate")}
                className="text-input"
                type="date"
                id="endDate"
                name="endDate"
                required
                />
              </div>
              
          </div>
          <div className="form-container">
            <textarea
              placeholder={t("contactMessage")}
              className="text-input"
              id="message"
              name="message"
            />
          </div>
          <div>
            <input
            type="checkbox"
            onChange={(e) => setCheck(e.target.checked) }/>
            <span>{" "}{t("contactAccept")} <Link to="/avisoLegal">{t("aviso.h2")}</Link>{t("reserva.p_4")}<Link to="/reservas">{t("reserva.h2")}</Link>{t("reserva.p_5")}<Link to="/privacidad">{t("privacidad.h2")}</Link></span>
          </div><br/>
          <div>
            {!check ? <h5>{t("contactNotAccepted")}</h5> : sendingInProgress ? <CircularProgress size="2rem" hidden={!sendingInProgress} /> :
            <input
              className="s-button"
              type="submit"
              value={t("contactSend")}
            ></input>
            }
            
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
